import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Geocode from 'react-geocode';
import GoogleMapReact from 'google-map-react';
import { Popover } from '@material-ui/core';

import { usePlaces, useLanguage } from '../../hooks';

import { ChangeLanguage } from '../../components';

import { Container, Map, Header, Sidebar, Footer, Plus, TopHeader, Logo, IconMarker, Marker } from './styles';

Geocode.setApiKey(`${process.env.REACT_APP_GOOGLE_API_KEY}`);

const MarkerComponent = ({ text, lat, lng }: { text: string; lat: number; lng: number }) => {
  const [open, setOpen] = useState(false);
  return (
    <IconMarker>
      <Popover
        open={open}
        onClose={() => setOpen(false)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
      >
        <span>{text}</span>
      </Popover>
      <Marker onClick={() => setOpen(true)} />
    </IconMarker>
  );
};

const PlacesMap: React.FC = () => {
  const { t } = useTranslation();
  const { places } = usePlaces();
  const { language } = useLanguage();

  const lat = language === 'pt' ? -23.5489 : 40.73061;
  const lng = language === 'pt' ? -46.6388 : -73.935242;

  const currentZoom = 13;

  return (
    <Container>
      {/* For mobile screens */}
      <Header className="mobile">
        <Link to="/">
          <Logo />
        </Link>
        <ChangeLanguage />
      </Header>

      <Sidebar>
        <Header className="desktop">
          <TopHeader>
            <Link to="/">
              <Logo />
            </Link>

            <ChangeLanguage />
          </TopHeader>

          <h2>{t('orph-map-title')}</h2>

          <p>{t('orph-map-text1')}</p>
        </Header>

        <Footer>
          <strong>{t('homepage-text2')}</strong>
          <span>{t('homepage-text3')}</span>
        </Footer>
      </Sidebar>

      <Map>
        <GoogleMapReact bootstrapURLKeys={{ key: String(process.env.REACT_APP_GOOGLE_API_KEY) }} defaultCenter={{ lat, lng }} defaultZoom={currentZoom}>
          {places.map(place => (
            <MarkerComponent key={place.id} text={place.name} lat={Number(place.latitude)} lng={Number(place.longitude)} />
          ))}
        </GoogleMapReact>
        <Link to="/create" className="button-secondary">
          <Plus />
        </Link>
      </Map>
    </Container>
  );
};

export default PlacesMap;
