import styled from 'styled-components';
import { FiArrowRight } from 'react-icons/fi';

import logo from '~/assets/svg/logo.svg';
import homepageBg from '~/assets/svg/homepage-bg.svg';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;

  padding: 15px;

  @media (min-width: 400px) {
    padding: 50px;
  }
`;

export const Wrapper = styled.div`
  position: relative;

  width: 100%;
  max-width: 1200px;

  height: 100%;
  max-height: 680px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  background: url(${homepageBg}) no-repeat;
  background-position: 0% 100%;
  background-size: 20%;

  & .enter-app {
    position: absolute;
    bottom: 0;
    right: 0;

    border-radius: var(--border-radius);

    width: 50px;
    height: 50px;

    background-color: var(--color-primary);

    display: flex;
    justify-content: center;
    align-items: center;

    text-decoration: none;

    transition: background-color var(--transition-fast);

    &:hover {
      background-color: var(--color-secondary-light);
      transition: background-color var(--transition-fast);

      text-decoration: none;
    }
  }

  @media (min-width: 590px) {
    align-items: flex-start;
    justify-content: space-between;
    background-position: 100% 85%;
    & .enter-app {
      width: 60px;
      height: 60px;
    }
  }

  @media (min-width: 768px) {
    background-position: 100% center;
    background-size: 50%;
    & .enter-app {
      width: 80px;
      height: 80px;
    }
  }

  @media (min-width: 1100px) {
    background-position: 80% center;
    background-size: contain;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  max-height: 150px;

  width: 100%;
  height: 100%;

  .change-language {
    margin: 10px 0 0 25px;
  }

  @media (min-width: 400px) {
    flex-direction: row;
    max-height: 71px;
  }

  @media (min-width: 590px) {
    justify-content: flex-start;
  }
`;

export const Logo = styled.div`
  background: url(${logo}) no-repeat 100% center;
  width: 100%;
  height: 100%;
  max-height: 71px;
  max-width: 240px;
`;

export const Main = styled.main`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  > h1,
  p {
    text-align: center;
  }

  > h1 {
    font-size: 48px;
    font-weight: 800;
    line-height: 48px;
  }

  > p {
    margin-top: 80px;
    font-size: 24px;
    line-height: 34px;
  }

  @media (min-width: 590px) {
    max-width: 350px;
    height: auto;

    > h1,
    p {
      text-align: left;
    }

    > h1 {
      font-size: 76px;
      line-height: 70px;
    }
  }
`;

export const Location = styled.div`
  text-align: center;

  display: none;

  font-size: 24px;
  line-height: 34px;

  flex-direction: column;

  > strong {
    font-weight: 800;
  }

  @media (min-width: 460px) {
    display: flex;
  }

  @media (min-width: 590px) {
    position: absolute;
    top: 0;
    right: 0;

    text-align: right;
  }
`;

export const ArrowRight = styled(FiArrowRight)`
  width: 26px;
  height: 26px;
  color: rgba(0, 0, 0, 0.6);
`;
