import { withStyles } from '@material-ui/core/styles';
import { Checkbox } from '@material-ui/core';

const StyledCheckbox = withStyles({
  root: {
    outline: 0,
    color: 'var(--color-secondary)',
    cursor: 'pointer',
    '& svg': {
      width: '20px',
      height: '20px',
    },
    '&$checked': {
      color: 'var(--color-secondary)',
    },
  },
  checked: {
    color: 'var(--color-secondary)',
  },
})(Checkbox);

export default StyledCheckbox;
