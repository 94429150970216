import React, { useState, useCallback, ChangeEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Formik } from 'formik';
import PhoneInput from 'react-phone-input-2';
import Geocode from 'react-geocode';
import GoogleMapReact from 'google-map-react';

import { useLanguage } from '../../hooks';

import Schemas from '../../validators';

import { ReducedSidebar, ButtonContent } from '../../components';

import { TextField, ButtonOutlined, Checkbox } from '../../styles/MaterialUI';
import { Container, DeleteIcon, Main, Form, PlusIcon, IconMarker, Marker } from './styles';
import 'react-phone-input-2/lib/material.css';
import api from '../../services/api';

interface RequestProps {
  name: string;
  about: string;
  whatsapp: string;
  instructions: string;
  opening_hours: string;
  open_on_weekends: boolean;
  latitude: string;
  longitude: string;
}

Geocode.setApiKey(`${process.env.REACT_APP_GOOGLE_API_KEY}`);

const MarkerComponent = ({ text, lat, lng }: { text: string; lat: number; lng: number }) => {
  return (
    <IconMarker>
      <Marker />
    </IconMarker>
  );
};

const CreatePlaces: React.FC = () => {
  const { t } = useTranslation();
  const { language } = useLanguage();

  const [loading, setLoading] = useState(false);
  const [focused, setFocused] = useState(false);

  const history = useHistory();

  const [images, setImages] = useState<File[]>([]);
  const [previewImages, setPreviewImages] = useState<string[]>([]);

  const lat = language === 'pt' ? -23.5489 : 40.73061;
  const lng = language === 'pt' ? -46.6388 : -73.935242;

  const getPhoneInputClass = useCallback(
    (touched: boolean | undefined, error: string | undefined) => {
      if (focused) {
        return 'react-tel-input-touched';
      }

      if (touched && error) {
        return 'error-special-label';
      }

      return 'react-tel-input';
    },
    [focused]
  );

  function handleMapClick(value: GoogleMapReact.ClickEventValue, setField: (field: string, value: any, shouldValidate?: boolean | undefined) => void) {
    setField('latitude', value.lat);
    setField('longitude', value.lng);

    Geocode.fromLatLng(`${value.lat}`, `${value.lng}`).then(
      response => {
        const { compound_code } = response.plus_code;
        if (compound_code) {
          // setLocation(compound_code.split(/(?<=^\S+)\s/)[1]);
          setField('adress', response.results[0].formatted_address);
        } else {
          setField('adress', t('pointlocationnf'));
          // setLocation(t('pointlocationnf'));
        }
      },
      error => {
        setField('adress', t('pointlocationnf'));
        // setLocation(t('pointlocationnf'));
      }
    );
  }

  async function handleSubmit(data: RequestProps) {
    setLoading(true);
    try {
      const body = new FormData();

      body.append('name', data.name);
      body.append('about', data.about);
      body.append('latitude', data.latitude);
      body.append('longitude', data.longitude);
      body.append('instructions', data.instructions);
      body.append('opening_hours', data.opening_hours);
      body.append('open_on_weekends', String(data.open_on_weekends));

      images.forEach(image => {
        body.append('images', image);
      });

      await api.post('places', data);

      // alert('Cadastro realizado com sucesso!');

      history.push('/app');
    } catch (err) {
    } finally {
      setLoading(false);
    }
  }

  const handleSelectImages = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event.target.files) {
      return;
    }

    const selectedImages = Array.from(event.target.files);

    images.map(image => selectedImages.push(image));

    setImages(selectedImages);

    const selectedImagesPreview = selectedImages.map(image => {
      return URL.createObjectURL(image);
    });

    setPreviewImages(selectedImagesPreview);
  };

  const handleDeleteImage = (index: number) => {
    const newImages = images;
    newImages.splice(index, 1);

    setImages(newImages);

    const selectedImagesPreview = newImages.map(image => {
      return URL.createObjectURL(image);
    });

    setPreviewImages(selectedImagesPreview);
  };

  const changeLocation = (field: 'latitude' | 'longitude', value: string, setField: (field: string, value: any, shouldValidate?: boolean | undefined) => void) => {
    if (!isNaN(Number(value))) {
      setField(field, value);
    }
  };

  return (
    <Container>
      <ReducedSidebar />
      <Main>
        <Formik
          initialValues={{
            name: '',
            about: '',
            whatsapp: '',
            instructions: '',
            opening_hours: '',
            open_on_weekends: false,
            adress: '',
            latitude: '',
            longitude: '',
          }}
          validationSchema={Schemas('create')}
          onSubmit={async (values, { setSubmitting }) => {
            setSubmitting(true);
            await handleSubmit(values);
            setSubmitting(false);
          }}
        >
          {}
          {({ values, errors, touched, handleChange, handleBlur, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <div className="components">
                <div className="section-title">{t('basic-info')}</div>

                <div className="input-block">
                  <TextField
                    error={!!(touched.name && errors.name)}
                    name="name"
                    type="text"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.name}
                    autoFocus
                    required
                    label={t('name')}
                    helperText={touched.name && errors.name ? errors.name : ''}
                  />
                </div>

                <div className="input-block">
                  <TextField
                    error={!!(touched.about && errors.about)}
                    name="about"
                    type="text"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.about}
                    multiline
                    label={t('about')}
                    helperText={touched.about && errors.about ? errors.about : ''}
                    inputProps={{
                      maxLength: 300,
                    }}
                  />
                </div>

                <div className="input-block phone-block">
                  <PhoneInput
                    placeholder={t('whatsapp')}
                    specialLabel={t('whatsapp') + ' *'}
                    inputClass={`form-control ${touched.whatsapp && errors.whatsapp ? ' error' : ''}`}
                    containerClass={getPhoneInputClass(touched.whatsapp, errors.whatsapp)}
                    inputProps={{
                      placeholder: t('whatsapp'),
                      name: 'whatsapp',
                      id: 'whatsapp',
                      className: `form-control ${touched.whatsapp && errors.whatsapp ? ' error' : ''}`,
                      required: true,
                    }}
                    country={language === 'pt' ? 'br' : 'us'}
                    preferredCountries={['br', 'us']}
                    value={values.whatsapp}
                    onChange={phone => setFieldValue('whatsapp', phone)}
                    onBlur={e => {
                      setFocused(false);
                      handleBlur(e);
                    }}
                    onFocus={() => setFocused(true)}
                    countryCodeEditable={false}
                  />
                  <div className="error-message">{touched.whatsapp && errors.whatsapp ? errors.whatsapp : ''}</div>
                </div>

                <div className="section-title">{t('localization')}</div>

                <div className="input-block input-row">
                  <TextField
                    error={!!(touched.latitude && errors.latitude)}
                    name="latitude"
                    type="text"
                    variant="outlined"
                    onChange={e => changeLocation('latitude', e.target.value, setFieldValue)}
                    onBlur={handleBlur}
                    value={values.latitude}
                    label={t('latitude')}
                    required
                    helperText={touched.latitude && errors.latitude ? errors.latitude : ''}
                    inputProps={{
                      maxLength: 300,
                    }}
                  />

                  <TextField
                    error={!!(touched.longitude && errors.longitude)}
                    name="longitude"
                    type="text"
                    variant="outlined"
                    onChange={e => changeLocation('longitude', e.target.value, setFieldValue)}
                    onBlur={handleBlur}
                    value={values.longitude}
                    label={t('longitude')}
                    required
                    helperText={touched.longitude && errors.longitude ? errors.longitude : ''}
                    inputProps={{
                      maxLength: 300,
                    }}
                  />
                </div>

                <div className="map-container margin-30">
                  <GoogleMapReact
                    bootstrapURLKeys={{ key: String(process.env.REACT_APP_GOOGLE_API_KEY) }}
                    defaultCenter={{ lat, lng }}
                    center={{ lat: values.latitude ? Number(values.latitude) : lat, lng: values.longitude ? Number(values.longitude) : lng }}
                    defaultZoom={13}
                    onClick={value => handleMapClick(value, setFieldValue)}
                  >
                    <MarkerComponent text={values.adress} lat={Number(values.latitude)} lng={Number(values.longitude)} />
                  </GoogleMapReact>
                </div>

                <div className="input-block margin-20">
                  <TextField
                    error={!!(touched.adress && errors.adress)}
                    name="adress"
                    type="text"
                    variant="outlined"
                    disabled
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.adress}
                    label={t('adress')}
                    helperText={touched.adress && errors.adress ? errors.adress : ''}
                  />
                </div>

                <div className="section-title">{t('pictures')}</div>

                <div className="input-block">
                  <div className="images-container">
                    <label htmlFor="image-array" className="new-image">
                      <PlusIcon />
                    </label>
                    <input multiple onChange={handleSelectImages} type="file" id="image-array" />
                  </div>
                  <div className="selected-images">
                    {previewImages.map((image, index) => {
                      return (
                        <div className="image-square" key={image}>
                          <DeleteIcon onClick={() => handleDeleteImage(index)} />
                          <img src={image} alt={values.name} />
                        </div>
                      );
                    })}
                  </div>
                </div>

                <div className="section-title">{t('visitation')}</div>

                <div className="input-block">
                  <TextField
                    error={!!(touched.instructions && errors.instructions)}
                    name="instructions"
                    type="text"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.instructions}
                    multiline
                    label={t('instructions')}
                    helperText={touched.instructions && errors.instructions ? errors.instructions : ''}
                    inputProps={{
                      maxLength: 300,
                    }}
                  />
                </div>

                <div className="input-block">
                  <TextField
                    error={!!(touched.opening_hours && errors.opening_hours)}
                    name="opening_hours"
                    type="text"
                    variant="outlined"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.opening_hours}
                    multiline
                    required
                    label={t('opening_hours')}
                    helperText={touched.opening_hours && errors.opening_hours ? errors.opening_hours : ''}
                    inputProps={{
                      maxLength: 300,
                    }}
                  />
                </div>

                <div className="checkbox-block">
                  <Checkbox name="open_on_weekends" checked={values.open_on_weekends} onChange={() => setFieldValue('open_on_weekends', !values.open_on_weekends)} />
                  <p>{t('open_on_weekends')}</p>
                </div>

                <div className="submit-button">
                  <ButtonOutlined type={!loading ? 'submit' : 'button'} disabled={loading}>
                    <ButtonContent loading={loading} text="confirm" />
                  </ButtonOutlined>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </Main>
    </Container>
  );
};

export default CreatePlaces;
