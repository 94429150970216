import React, { createContext, useState, useCallback, useContext, useEffect } from 'react';

import api from '../services/api';
import notify from '../services/toast';

const PlacesContext = createContext<PlacesContextProps>({} as PlacesContextProps);

interface PlacesResponse {
  id: string;
  name: string;
  latitude: string;
  longitude: string;
  whatsapp: string;
  about: string;
  instructions: string;
  opening_hours: string;
  open_on_weekends: boolean;
  images: Array<{
    id: string;
    path: string;
    url: string;
    place_id: string;
  }>;
}

interface PlacesContextProps {
  places: PlacesResponse[];
  loading: boolean;
}

const PlacesProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);

  const [places, setPlaces] = useState<PlacesResponse[]>([]);

  const loadPlaces = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get('/places');
      const { data } = response;
      setPlaces(data);
    } catch (err) {
      notify(err?.response?.data?.message ? err.response.data.message : err.message, 'error');
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadPlaces();
  }, [loadPlaces]);

  return <PlacesContext.Provider value={{ places, loading }}>{children}</PlacesContext.Provider>;
};

const usePlaces = (): PlacesContextProps => {
  const context = useContext(PlacesContext);

  if (!context) {
    throw new Error('usePlaces must be used within an PlacesProvider');
  }

  return context;
};

export { PlacesProvider, usePlaces };
