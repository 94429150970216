import React from 'react';
import { useHistory, Link } from 'react-router-dom';

import ChangeLanguage from '../ChangeLanguage';

import { Container, Logo, ArrowBack, Footer, Header } from './styles';

const ReducedSidebar: React.FC = () => {
  const { goBack } = useHistory();
  return (
    <Container>
      <Header>
        <Link to="/">
          <Logo />
        </Link>
        <ChangeLanguage />
      </Header>

      <Footer>
        <button type="button" onClick={goBack}>
          <ArrowBack />
        </button>
      </Footer>
    </Container>
  );
};

export default ReducedSidebar;
