import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  :root {
    --color-primary: #FFD666;
    --color-primary-lighter: #f9e19e;

    --color-secondary: #29b6d1;
    --color-secondary-light: #96FEFF;
    --color-secondary-lighter: #00c7c7;
    --color-secondary-dark: #12afcb;

    --font-family: 'Nunito', 'Roboto', sans-serif;
    --transition: 0.6s ease-in-out !important;
    --transition-fast: 0.4s ease-in-out !important;
    --body-background: #ebf2f5;
    --body-background-secondary: #35373a;

    --text-color: #8fa7b3;

    --scrol-thumb: #333;
    --scrol-bg: #89777a;

    --placeholder-color: #a7a8a9;

    --border-radius: 20px;

    --disabled: #9e9e9e;

    --menu-hover: #787777;
    --menu-background: #35373a;
    --box-shadow:  0px 1px 2px rgba(0, 0, 0, 0.15);

    --border:1px solid #d3e2e5;
    --color-red-label: #f44336;

    --gradient-background: linear-gradient(329.54deg, var(--color-secondary) 0%, var(--color-secondary-lighter) 100%);
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    scroll-behavior: smooth;
    outline: none !important;
    /* transition: all var(--transition-fast); */
  }

  html, body, #root {
    scroll-behavior: smooth;
    font-family: var(--font-family);
    background: var(--gradient-background);
  }

  input, button, textarea {
    font: 600 18px var(--font-family);
  }

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: var(--scrol-bg);
    border-radius: 4px;
    transition: var(--transition);
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: var(--scrol-thumb);
    transition: var(--transition);
  }

  .CookieConsent{
    border-radius: 5px;
    bottom: 40px !important;
    width: 80% !important;
    left: 10% !important;
  }

  .cookie-subtitle {
    font-size: 10px;
    line-height: 14px;
  }

  .react-tel-input .form-control:focus {
    border: 1px solid var(--color-secondary) !important;
    box-shadow: 0px 0px 1px var(--color-secondary) !important;
    transition: var(--transition);
  }

  .react-tel-input-touched .special-label{
    color: var(--color-secondary) !important;
    transition: var(--transition);
  }

  .react-tel-input .form-control:hover {
    border: 1px solid var(--color-secondary) !important;
    box-shadow: 0px 0px 1px var(--color-secondary) !important;
    transition: var(--transition);
  }

  .react-tel-input .country-list {
    color: var(--text-color);
  }
`;
