import React from 'react';
import { MenuItem } from '@material-ui/core';

import { useLanguage } from '../../hooks';

import { ReactComponent as BrazilIcon } from '~/assets/svg/brazil.svg';
import { ReactComponent as USAIcon } from '~/assets/svg/usa.svg';

import { useStyles, Select } from '../../styles/MaterialUI';
import { Container } from './styles';

const ChangeLanguage: React.FC = () => {
  const { language, changeLanguage } = useLanguage();
  const classes = useStyles();
  return (
    <Container className="change-language">
      <Select value={language} onChange={e => changeLanguage(String(e.target.value))} MenuProps={{ classes: { paper: classes.selectOptions } }}>
        <MenuItem value="en">
          <USAIcon />
          <span>English (United States)</span>
        </MenuItem>
        <MenuItem value="pt">
          <BrazilIcon />
          <span>Português (Brasil)</span>
        </MenuItem>
      </Select>
    </Container>
  );
};

export default ChangeLanguage;
