import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import { isValidPhoneNumber } from 'react-phone-number-input';

export default function Schemas(schema: string): Yup.ObjectSchema | undefined {
  const { t } = useTranslation();

  const createSchema = Yup.object().shape({
    name: Yup.string().trim(t('validname')).nullable(false).required(t('requiredname')),
    about: Yup.string().trim(t('validabout')).nullable(false),
    whatsapp: Yup.string()
      .test('Whats App', t('validwhatsapp'), (value: string | null | undefined) => {
        if (value && !isValidPhoneNumber(`+${value}`)) {
          return false;
        }
        return true;
      })
      .required(t('requiredwhatsapp')),
    latitude: Yup.number().required(t('pointlocationrequired')),
    longitude: Yup.number().required(t('pointlocationrequired')),
    instructions: Yup.string().trim(t('validabout')).nullable(false),
    opening_hours: Yup.string().trim(t('validopenhours')).nullable(false).required(t('requiredopenhours')),
  });

  switch (schema) {
    case 'create':
      return createSchema;
    default:
      break;
  }
}
