import React from 'react';
import { BrowserRouter } from 'react-router-dom';
// import { ToastContainer } from 'react-toastify';

import Routes from './routes';

import GlobalStyles from './styles/GlobalStyles';
import './styles/ReactToastify.css';

const App: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        {/* <ToastContainer /> */}
        <GlobalStyles />
        <Routes />
      </BrowserRouter>
    </>
  );
};

export default App;
