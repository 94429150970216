/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { RouteProps as ReactDOMRouteProps, Route as ReactDOMRoute } from 'react-router-dom';

import { Default } from '../Layouts';

interface RouteProps extends ReactDOMRouteProps {
  isDefault?: boolean;
  component: React.ComponentType;
  title?: string;
}

const Route: React.FC<RouteProps> = ({ title, component: Component, ...rest }) => {
  const Layout = Default;

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => (
        <Layout title={String(title)}>
          <Component />
        </Layout>
      )}
    />
  );
};

export default Route;
