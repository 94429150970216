import { FiArrowLeft } from 'react-icons/fi';
import styled from 'styled-components';

import { ReactComponent as logoMarker } from '~/assets/svg/marker.svg';

export const Container = styled.aside`
  height: 100%;
  padding: 32px 24px;
  background: var(--gradient-background);

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 860px) {
    height: 60px;
    flex-direction: row;
  }
`;

export const Header = styled.header`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;

  .MuiSelect-select.MuiSelect-select {
    padding-right: 0 !important;
  }

  .MuiSelect-icon {
    display: none !important;
  }

  @media (max-width: 860px) {
    flex-direction: row;

    .change-language {
      margin-left: 15px;
    }
  }
`;

export const Footer = styled.footer`
  button {
    width: 48px;
    height: 48px;

    border: 0;

    background: var(--color-secondary-dark);
    border-radius: 16px;

    cursor: pointer;

    transition: background-color var(--transition);

    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      background: var(--color-secondary-lighter);
      transition: background-color var(--transition);
    }

    @media (max-width: 860px) {
      width: 35px;
      height: 35px;
    }
  }
`;

export const Logo = styled(logoMarker)`
  width: 55px;
  height: 55px;

  cursor: pointer;

  @media (max-width: 860px) {
    width: 40px;
    height: 40px;
  }
`;

export const ArrowBack = styled(FiArrowLeft)`
  width: 15px;
  height: 15px;
  color: white;

  cursor: pointer;
`;
