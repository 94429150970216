import React from 'react';

import { Container } from './styles';

interface Props {
  title: string;
}

const Default: React.FC<Props> = ({ children, title }) => {
  document.title = `${title ? `${title} | ` : ''}Happy`;
  return <Container>{children}</Container>;
};

export default Default;
