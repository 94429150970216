import React from 'react';

import { LanguageProvider, useLanguage } from './useLanguage';
import { CookieProvider, useCookie } from './useCookie';
import { PlacesProvider, usePlaces } from './usePlaces';

const AppProvider: React.FC = ({ children }) => {
  return (
    <CookieProvider>
      <LanguageProvider>
        <PlacesProvider>{children}</PlacesProvider>
      </LanguageProvider>
    </CookieProvider>
  );
};

export { useLanguage, useCookie, usePlaces };
export default AppProvider;
