import styled from 'styled-components';

export const Container = styled.div`
  .MuiInput-underline:before,
  .Mui-focused:before {
    border-bottom: transparent !important;
  }

  .MuiInput-underline:after {
    border-bottom: transparent !important;
  }

  .MuiSelect-icon {
    margin-top: -5px;
    margin-right: -5px;
    color: var(--text-color);
  }
`;
