import { FiPlus, FiTrash } from 'react-icons/fi';
import styled from 'styled-components';

import { ReactComponent as logoMarker } from '~/assets/svg/marker.svg';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background-color: var(--body-background);
  overflow: hidden;

  @media (max-width: 860px) {
    flex-direction: column;
  }
`;

export const Main = styled.main`
  flex: 1;
  padding: 64px 0;

  @media (max-width: 860px) {
    height: calc(100% - 60px);
    padding: 15px 0;
  }
`;

export const Form = styled.form`
  width: 700px;
  margin: auto;

  height: 100%;
  background: white;
  border: var(--border);
  box-shadow: var(--box-shadow);
  border-radius: 20px;
  padding: 44px 40px;

  .components {
    padding: 10px 20px;
    width: 100%;
    height: 100%;

    overflow-y: auto;

    .MuiFormControl-root {
      width: 100% !important;
    }

    .react-tel-input {
      .form-control {
        height: 40px;
        font-size: 14px;
        width: 100%;
        color: var(--text-color);
      }
    }

    .special-label {
      font-family: var(--font-family);
      font-size: 11px;
      margin-left: -16px;
      font-weight: 500;
      padding-right: 20px;
      margin-top: -6px;
      transition: var(--transition);
      color: rgba(0, 0, 0, 0.54);

      height: 14px;
    }

    .error-field {
      .special-label {
        color: var(--color-red-label);
      }
    }

    .error-message {
      font-size: 12px;
      bottom: 0;
      position: absolute;
      font-family: var(--font-family);

      color: var(--color-red-label);
      margin: 3px 14px 0;

      text-align: left;
      font-weight: 400;
      line-height: 1.66;
      letter-spacing: 0.03333em;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
      width: 90%;
    }

    .error {
      border: 1px solid red;
      transition: var(--transition);
    }

    .error-special-label {
      .special-label {
        color: var(--color-red-label) !important;
        transition: var(--transition);
      }
    }

    .section-title {
      width: 100%;

      font-size: 32px;
      line-height: 34px;
      color: #5c8599;
      font-weight: 700;

      border-bottom: var(--border);
      margin-bottom: 30px;
      padding-bottom: 24px;
    }

    .phone-block {
      position: relative;
      height: 60px;
      margin-bottom: 10px;
    }

    .input-row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 15px;

      .MuiFormControl-root {
        width: 47.5% !important;
      }
    }

    .map-container {
      border-radius: var(--border-radius);
      width: 100%;
      height: 100%;
      max-height: 350px;
    }

    .input-block + .input-block {
      margin-top: 20px;
    }

    .images-container {
      display: flex;
      align-items: center;
      justify-content: center;

      .new-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 64px;
        background: #f5f8fa;
        border: 1px dashed #96d2f0;
        border-radius: 20px;
        cursor: pointer;
      }

      #image-array {
        display: none;
      }
    }

    .selected-images {
      margin: 10px 0 30px 0;
      display: flex;
      align-items: center;
      width: 100%;
      max-width: 100%;
      padding: 10px;

      overflow-x: auto;

      .image-square + .image-square {
        margin-left: 10px;
      }

      .image-square {
        position: relative;
        height: 150px;
        img {
          max-height: 150px;
        }
      }
    }

    .margin-20 {
      margin-bottom: 20px;
    }

    .margin-30 {
      margin-bottom: 30px;
    }

    .checkbox-block {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      p {
        color: var(--text-color);
        font-size: 14px;
      }
    }
  }

  @media (max-width: 860px) {
    width: 90%;
    padding: 24px 20px;
  }
`;

export const PlusIcon = styled(FiPlus)`
  width: 25px;
  height: 25px;
  color: var(--color-secondary);
`;

export const DeleteIcon = styled(FiTrash)`
  width: 25px;
  height: 25px;
  color: var(--color-secondary);

  cursor: pointer;

  position: absolute;
  top: 0;
  right: 0;
  margin: 5px;
  background: white;
  padding: 5px;
  border-radius: 50%;

  opacity: 0.8;
  transition: opacity var(--transition);

  &:hover {
    opacity: 1;
    transition: opacity var(--transition);
  }
`;

export const IconMarker = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 55px;
  height: 55px;
  max-width: 200px;
  span {
    color: black;
  }
`;

export const Marker = styled(logoMarker)`
  width: 55px;
  height: 55px;

  cursor: pointer;
`;
