import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ChangeLanguage } from '../../components';

import { Container, Wrapper, Main, Location, ArrowRight, Logo, Header } from './styles';

const Homepage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container>
      <Wrapper>
        <Header>
          <Logo />
          <ChangeLanguage />
        </Header>

        <Main>
          <h1>{t('homepage-title')}</h1>
          <p>{t('homepage-text1')}</p>
        </Main>

        <Location>
          <strong>{t('homepage-text2')}</strong>
          <span>{t('homepage-text3')}</span>
        </Location>

        <Link to="/places" className="enter-app">
          <ArrowRight />
        </Link>
      </Wrapper>
    </Container>
  );
};

export default Homepage;
