import styled from 'styled-components';
import { FiPlus } from 'react-icons/fi';

import marker from '~/assets/svg/marker.svg';
import logo from '~/assets/svg/logo.svg';

import { ReactComponent as logoMarker } from '~/assets/svg/marker.svg';

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;

  flex-direction: column;

  & .desktop {
    display: none;
  }

  @media (min-width: 590px) {
    flex-direction: row;

    & .mobile {
      display: none;
    }

    & .desktop {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const Sidebar = styled.aside`
  width: 440px;
  background: var(--gradient-background);
  padding: 80px;

  display: flex;
  justify-content: space-between;
  flex-direction: column;

  height: 100%;

  display: none;

  @media (min-width: 590px) {
    display: inline;
  }
`;

export const Map = styled.div`
  height: 100%;
  width: 100%;
  background: var(--body-background);
  & .button-secondary {
    position: absolute;
    bottom: 25px;
    right: 80px;

    border-radius: var(--border-radius);

    width: 80px;
    height: 80px;

    background-color: var(--color-secondary);

    display: flex;
    justify-content: center;
    align-items: center;

    text-decoration: none;

    transition: background-color var(--transition-fast);

    &:hover {
      background-color: var(--color-primary);
      transition: background-color var(--transition-fast);

      text-decoration: none;
    }
  }
`;

export const TopHeader = styled.div`
  width: 100%;
  height: 100%;

  max-height: 72px;

  display: flex;
  align-items: center;

  .change-language {
    margin-left: 25px;
  }
`;

export const Logo = styled.div`
  background: url(${marker}) no-repeat 100% center;
  width: 100%;
  height: 100%;
  max-height: 72px;
  max-width: 64px;

  @media (max-width: 590px) {
    background: url(${logo}) no-repeat 100% center;
    background-size: contain;
    max-height: 40px;
    max-width: 140px;
  }
`;

export const Header = styled.header`
  @media (max-width: 590px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    height: 60px;
    padding: 10px;
  }

  height: 100%;

  > h2 {
    font-size: 40px;
    font-weight: 800;
    line-height: 42px;
    margin-top: 64px;
  }
  > p {
    margin-top: 24px;
    line-height: 28px;
  }

  a {
    width: 100%;
    height: 100%;
    max-height: 72px;
    max-width: 64px;
  }

  @media (max-width: 590px) {
    a {
      max-height: 40px;
      max-width: 140px;
    }
  }
`;

export const IconMarker = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 200px;
  height: 50px;
  max-width: 200px;
  background-color: white;
  span {
    color: black;
  }
`;

export const Footer = styled.footer`
  display: flex;
  flex-direction: column;
  line-height: 24px;
  > strong {
    font-weight: 800;
  }
`;

export const Plus = styled(FiPlus)`
  width: 26px;
  height: 26px;
  color: white;
`;

export const Marker = styled(logoMarker)`
  width: 55px;
  height: 55px;

  cursor: pointer;
`;
