import React from 'react';
import { useTranslation } from 'react-i18next';
import { Switch } from 'react-router-dom';

import Route from './Route';

import { Homepage, PlaceInfo, PlacesMap, CreatePlaces } from '../pages';

const Routes: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Switch>
      <Route path="/" title={t('homepage')} exact component={Homepage} />
      <Route path="/places" title={t('places')} component={PlacesMap} />

      <Route path="/create" title={t('createPlace')} component={CreatePlaces} />
      <Route path="/info/:place" title={t('createPlace')} component={PlaceInfo} />
    </Switch>
  );
};

export default Routes;
